import { Introduction, Participant, RoundtableUser } from "interfaces";

export const openAppOrBrowser = async ({
    inviteCode,
    roomId,
    userId,
}: {
    inviteCode?: string;
    roomId?: string;
    userId?: string;
}) => {
    // const deeplinkUrl = `${process.env.REACT_APP_SCHEMA}invited=${roomId}`;
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (userId) {
        await navigator.clipboard
            .writeText(`userId:${userId}`)
    }
    else if (roomId) {
        await navigator.clipboard
            .writeText(`invited:${roomId}`)
    }
    else if (inviteCode) {
        await navigator.clipboard
            .writeText(`inviteCode:${inviteCode}`)
    }


    if (isAndroid) {
        window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
    } else {
        window.location.href = process.env.REACT_APP_STORE_URL ?? "";
    }
}

export const getUserMediaInfo = (user: RoundtableUser, introductions: Introduction[], playingStates: {
    [key: string]: boolean;
}) => {
    const intro = user.UserRoundtableStatuses?.introId
        ? introductions.find(intro => intro.introId === user.UserRoundtableStatuses.introId)
        : null;

    const isUserPlaying = playingStates[intro?.introId || ''] ?? false;
    const isAudio = intro && intro.type === 'audio' ? true : false;

    return { intro, isUserPlaying, isAudio };
};


export const goToStore = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isAndroid) {
        window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
    } else {
        window.location.href = process.env.REACT_APP_STORE_URL ?? "";
    }
}

export const replaceUuidsWithUsernames = (message: string, users: any) => {
    let updatedMessage = message;

    users.forEach((user: Participant) => {
        const {
            name,
            UserRoundtableStatuses } = user;
        const regex = new RegExp(`@${UserRoundtableStatuses.participantId}`, 'g');
        updatedMessage = updatedMessage.replace(regex, `@${name}`);
    });
    return updatedMessage;
};