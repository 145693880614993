import { replaceUuidsWithUsernames } from "Utils";
import phonePreview from "assets/images/iphone-prev.png";
import placeHolder from 'assets/images/placeholder-img.png';
import ScrollGestureIcon from "assets/images/scrolling-gesture.png";
import { default as avatorPlaceholder, default as userPlaceHolder } from 'assets/images/user-placeholder.svg';
import MessageBubble from 'components/MessageBubble';
import { PreviewSectionProps } from 'interfaces'; // Import PreviewSectionProps interface
import React, { useEffect, useState } from 'react';
import styles from './PreviewSection.module.css';


const PreviewSection: React.FC<PreviewSectionProps> = ({ data, onMessageBubbleClick }) => {
    const [overlayVisible, setOverlayVisible] = useState(true);
    const [isFadeAnimationEnd, setIsFadeAnimationEnd] = useState(true);

    useEffect(() => {
        const fadetimer = setTimeout(() => {
            setIsFadeAnimationEnd(false);
        }, 3000);

        const timer = setTimeout(() => {
            setOverlayVisible(false);
        }, 4000);

        return () => {
            clearTimeout(fadetimer);
            clearTimeout(timer);
        };
    }, []);

    const roundtableName = data?.roundtable?.name || 'RoundTable';
    const messages = data?.messages || [];
    const users = data?.roundtable?.RoundtableUsers || [];
    // Create a lookup table for participant IDs to user details
    const participantLookup = users.reduce((acc: any, user: any) => {
        acc[user.UserRoundtableStatuses.id] = {
            name: user.name,
            profile_picture: user.profile_picture,
        };
        return acc;
    }, {});

    return (
        <div className="md:mt-0 px-10 pb-8 mt-6 relative overflow-hidden" onClick={onMessageBubbleClick}>
            <div className="max-w-sm mx-auto relative">
                <div className="w-2/4 lg:ml-10 absolute whitespace-nowrap overflow-hidden text-ellipsis left-1/4 mt-[13%] lg:mt-[14%] ml-4 font-bold text-xl z-10 text-left text-gray-300 top-[1.4rem] md:top-[1.2rem] lg:top-[1.5rem]">
                    {roundtableName}
                </div>

                <img src={phonePreview} alt="App Logo" className="md:mx-8 md:w-[84%] lg:w-[100%] w-full h-auto" />

                <div className="absolute inset-0 flex items-center justify-center my-6 mx-2">
                    <div className={`w-full absolute mt-6 h-3/4 flex flex-col overflow-y-scroll md:w-[84%] lg:w-[100%] lg:ml-14 ${styles["no-scrollbar"]}`}>
                        {messages.length === 0 ? (
                            <div className="my-auto mx-4">
                                <img src={placeHolder} alt="No messages" />
                            </div>

                        ) : (
                            messages.slice().reverse().map((message: any) => {
                                const user = participantLookup[message.participantId] || { name: ' Former User', profile_picture: avatorPlaceholder };
                                const media = message.messageMedia?.media;
                                const formattedText = replaceUuidsWithUsernames(message.messageText?.content || '', users)
                                console.log("formattedText:", formattedText);
                                return (
                                    <MessageBubble
                                        key={message.id}
                                        userImage={user.profile_picture ? user.profile_picture : userPlaceHolder}
                                        text={formattedText || ''}
                                        username={user.name}
                                        image={media?.type === "image" ? media.path : undefined}
                                        audio={media?.type === "audio" ? media.path : undefined}
                                        video={media?.type === "video" ? media.path : undefined}
                                        onClick={() => onMessageBubbleClick && onMessageBubbleClick()}
                                    />
                                );
                            })
                        )}

                    </div>
                </div>
                {overlayVisible && (
                    <div className={`absolute inset-0 flex items-center justify-center my-6 mx-2 transition-opacity duration-1000 ${isFadeAnimationEnd ? 'opacity-100' : 'opacity-0'}`}>
                        <div className="md:w-[82%] lg:w-[100%] lg:ml-10 w-full bg-black bg-opacity-20 backdrop-blur-[20px] h-3/4 mt-6 flex flex-col">
                            <img src={ScrollGestureIcon} alt="App Logo" className="w-8 mt-auto mx-auto animate-bounce" />
                            <div className="mx-auto mb-auto text-white px-16 mt-4 font-bold">This is the Live Chat Preview Scroll to see more...</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PreviewSection;
