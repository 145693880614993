import { openAppOrBrowser } from 'Utils';
import AppStoreBtn from "assets/images/app-store-btn.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import BottomDialog from 'components/BottomDialog';
import DiscoverSection from 'components/DiscoverSection';
import FooterSection from 'components/FooterSection';
import HeroSection from 'components/HeroSection';
import Navbar from 'components/Navbar';
import ParticipantsSection from 'components/ParticipantsSection';
import PreviewSection from 'components/PreviewSection';
import { InviteContext } from 'context/inviteCodeContext';
import { Data } from 'interfaces';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchRoomData } from 'services/apiService';
import styles from './HomePage.module.css';

const HomePage: React.FC = () => {
    const [data, setData] = useState<Data | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, serError] = useState("");
    const participantsRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const roomId = queryParams.get('invited') || queryParams.get('roomId') || "";
    const backendEnv = queryParams.get('env') || null;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { inviteCodeParam } = useParams();  // Get the roomId from the path
    const { inviteCode, setInviteCode } = useContext(InviteContext);

    useEffect(() => {
        if (inviteCodeParam) {
            setInviteCode(inviteCodeParam);
        }

        const fetchData = async () => {
            try {
                const roomData = await fetchRoomData(roomId, inviteCodeParam || inviteCode, backendEnv);
                console.log(roomData);
                setData(roomData);
            } catch (error: any) {
                serError(error["message"]);
                setData(null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [inviteCodeParam, roomId, setInviteCode]);

    const scrollToParticipants = () => {
        if (participantsRef.current) {
            participantsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-r-2 border-cyan-300"></div>
            </div>
        );
    }



    const handleMessageClick = () => {
        console.log('Message clicked:');
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    }

    const handleDialogNext = () => {
        setIsDialogOpen(false);
        openAppOrBrowser({ roomId: roomId, inviteCode: inviteCode });
    }

    if (data == null) {
        return (
            <div>
                <Navbar userId={"asd"} />
                <div className="flex flex-col items-center justify-center h-screen text-center">
                    <h1 className="bg-gradient-to-r from-indigo-300 to-cyan-300 via-blue-30 text-transparent bg-clip-text text-8xl font-bold mb-6">404</h1>
                    <h1 className="text-xl font-bold mb-2 text-white"> This roundtable no longer exist</h1>
                    <p className="text-base text-neutral-300">This Roundtable may have been deleted by the creator.</p>
                </div>
                <div className={`bg-[#202223] w-full py-12 lg:py-20 ${styles['footer']} backdrop-blur-md mt-8`}>
                    <div className="hidden md:block">{/* Empty first column on larger screens */}</div>
                    <div className="flex flex-col items-center justify-center text-white space-y-4">
                        <p className='font-sans text-lg text-center px-10  lg:px-20 font-normal'>
                            Download the Roundtables app, complete your profile & connect.
                        </p>
                        <div className="flex flex-row justify-center gap-2">
                            <img src={AppStoreBtn} alt="App Store Logo" className="w-32 lg:w-44" onClick={() => { window.location.href = process.env.REACT_APP_STORE_URL ?? ""; }} />
                            <img src={GoogleStoreBtn} alt="Google Play Logo" className="w-36 lg:w-48" onClick={() => { window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? ""; }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`${styles['main-content']}`}>
            <Navbar roomId={roomId} />
            <ToastContainer />
            {data && <HeroSection roomId={roomId} introductions={data.introductions} data={data} scrollToParticipants={scrollToParticipants} onMessageBubbleClick={handleMessageClick} />}
            <DiscoverSection roomId={roomId} />
            <div className='md:hidden'>
                {data && <PreviewSection data={data} onMessageBubbleClick={handleMessageClick} />}
            </div>
            <FooterSection name={data?.roundtable.name ?? ""} roomId={roomId} />
            <div ref={participantsRef}>
                {data && <ParticipantsSection participants={data.roundtable.RoundtableUsers} introductions={data.introductions} />}
            </div>
            <BottomDialog isOpen={isDialogOpen} onNext={handleDialogNext} onClose={handleDialogClose} />
        </div>
    );
}

export default HomePage;
