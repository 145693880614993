import placeHolder from "assets/images/user-placeholder.svg";
import { UserContext } from "context/userCodeContext";
import { useContext, useEffect, useRef, useState } from "react";

export function UserInfoCard() {
    const { user } = useContext(UserContext);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        const imgElement = imageRef.current;

        if (imgElement) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsImageLoaded(true);
                        observer.disconnect();
                    }
                },
                {
                    rootMargin: "100px", // to start loading the image slightly before it enters the viewport
                }
            );

            observer.observe(imgElement);

            return () => {
                if (imgElement) {
                    observer.unobserve(imgElement);
                }
            };
        }
    }, []);

    return (
        <figure className="relative h-3/2 lg:h-4/5 w-full">
            <img
                ref={imageRef}
                className={`h-full w-full rounded-2xl ${user?.profile_picture ? "object-cover" : "object-fit"} object-center`}
                data-src={user?.profile_picture}
                style={{
                    boxShadow: "0 4px 40px rgba(96, 236, 251, 0.2)",
                    aspectRatio: "1",
                    width: "100%",
                    height: "100%",
                }}
                src={isImageLoaded ? (user?.profile_picture || placeHolder) : placeHolder}
                alt="nature image"
                loading="lazy"
            />
            <div
                className="absolute bottom-0 left-1/2 flex w-[100%] -translate-x-1/2 justify-center items-center rounded-b-2xl py-4 lg:py-10 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm"
                style={{ backgroundColor: "rgba(40, 40, 40, 0.5)" }}
            >
                <div className="text-center">
                    <h5 className="text-white font-sans text-2xl font-bold">{user?.name}</h5>
                    <p className="mt-2 text-white/60 font-sans text-lg font-normal truncate-3-lines">{user?.headline}</p>
                </div>
            </div>
        </figure>
    );
}
